import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';
import moment from 'moment'
import stream from 'mithril/stream'
import {
    Card,
    Zipcode
} from '@base/app/models'
import {
    Dialog,
    Paging,
    TextBox,
} from '@base/app/components'

import EditComponent from './edit'

export default class DashboardPage {
    constructor() {
        Card.pageIndex = 1
        Card.pageSize = 12
        this.foucus = false
        this.menus = ["從政人員", "公務人員", "公司", "個人", "廟宇", "記者"]
        Card.search_word = ""
        this.select_cate = ""
        this.countries = stream([])
        Zipcode.fetch().then((response) => {
            Object.keys(response).map((_item) => {
                let _country = {
                    name: response[_item][0].county,
                    datas: []
                }
                response[_item].map((_city) => {
                    _country.datas.push(_city.zipcode_name)
                })
                this.countries().push(_country)
            })
        })
    }
    oncreate() {
        anime({
            targets: '.text-card-play',
            translateY: [-250, 0],
            direction: 'alternate',
            loop: false,
            delay: 100,
            endDelay: 100,
            duration: 1000,
        });
        this.menus.map((item, index) => {
            anime({
                targets: `.card-play-fadeIn-${index}`,
                translateX: [2080, 0],
                direction: 'alternate',
                loop: false,
                delay: 100 * index,
                endDelay: 100 * index,
                duration: 500,
            });
        })
        anime({
            targets: `.card-play-fadeIn-5`,
            translateX: [2080, 0],
            direction: 'alternate',
            loop: false,
            delay: 100 * 5,
            endDelay: 100 * 5,
            duration: 500,
        });

    }
    view() {
        return m('.container-fluid.text-card-play.h-100', {
            style: {
                padding: "1% 2%",
                // backgroundImage: "linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%)"
                background:"#FFF"
            }
        }, [
            m(Dialog),

            m(".w-100", [
                m(".row.no-gutters.w-100.py-3.text-card-play", [
                    m(".col-md-1.pr-2.col-12", [
                        m(".row.no-gutters.w-100", {
                            style: {
                                fontSize: "0.9rem"
                            }
                        }, [
                            this.menus.map((_item, index) => {
                                return [
                                    m(`.col-md-12.py-2.d-none.d-md-block`, [
                                        m(`span.btn.btn-outline-success.w-100.card-play-fadeIn-${index}.border-0.font-weight-bold`, {
                                            style: {
                                                background: (this.select_cate == _item) ? "#A4D5D9" : "#DBE9EA",
                                                borderRadius: "15px",
                                                color: (this.select_cate == _item) ? "#FFF" : "#589BA0",
                                            },
                                            onclick: (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                this.select_cate = _item
                                                Card.search_word = ""
                                                Card.pageIndex = 1
                                                Card.load(Card.pageIndex, _item, Card.pageSize)

                                            }
                                        }, _item)
                                    ])
                                ]
                            }),
                            m(`.col-md-12.pt-5.d-none.d-md-block`, [
                                m(`span.btn.btn-primary.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                    style: {
                                        background: "#C7D9A4",
                                        borderRadius: "15px",
                                        color: "#FFF",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        Dialog.show(EditComponent, {
                                            attrs: {
                                                model: new Card(),
                                                country: this.countries
                                            },
                                            didHide: (returnValue) => {
                                                if (returnValue) {
                                                    if (Card.id != undefined) {
                                                        Card.load(Card.pageIndex, _item, Card.pageSize)
                                                    }

                                                }
                                            }
                                        })

                                    }
                                }, "新增")
                            ]),
                            m(`.col-md-12.pt-2`, [
                                m(TextBox, {
                                    oninput: (e) => {
                                        Card.search_word = e.target.value
                                    },
                                    value: Card.search_word,
                                    type: 'text',
                                    label: '關鍵字',
                                })
                            ]),
                            m(`.col-md-12.pt-2.d-none.d-md-block`, [
                                m(`span.btn.btn-info.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                    style: {
                                        background: "#A0A0A0",
                                        borderRadius: "15px",
                                        color: "#FFF",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.select_cate = ""
                                        Card.pageSize = 12
                                        Card.pageIndex = 1
                                        Card.load(Card.pageIndex, null, Card.pageSize)

                                    }
                                }, "查詢")
                            ]),
                            m(`.col-md-12.pt-2.d-block.d-md-none`, [
                                m(`span.btn.btn-info.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                    style: {
                                        background: "#A0A0A0",
                                        borderRadius: "15px",
                                        color: "#FFF",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.select_cate = ""
                                        Card.pageIndex = 1
                                        Card.pageSize = 4
                                        Card.load(Card.pageIndex, null, Card.pageSize)

                                    }
                                }, "查詢")
                            ]),
                            m(`.col-md-12.pt-2.d-none.d-md-block`, [
                                m(`span.btn.btn-primary.w-100.card-play-fadeIn-5.border-0.font-weight-bold`, {
                                    style: {
                                        background: "#A4BFD9",
                                        borderRadius: "15px",
                                        color: "#FFF",
                                    },
                                    onclick: (e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        let url = `./api/test/export`
                                        window.location.href = url

                                    }
                                }, "匯出")
                            ]),

                            m(".col-12.d-block.d-md-none", [
                                m('.row.no-gutters', [
                                    (!Card.loading) ? [Card.data.map((_data) => {
                                        return [
                                            m(".col-12.py-1", [
                                                m(".card", [
                                                    m(".card-body.py-2", [
                                                        m("h5.card-title", _data.name),
                                                        m("h6.card-subtitle.mb-2.text-muted", _data.company + " - " + _data.title),
                                                        m("span.d-block", _data.phone + " 分機: " + _data.phonecc),
                                                        m("span.d-block", _data.celephone),
                                                    ])
                                                ])
                                            ])
                                        ]
                                    }),] : m(".col-12.text-center.d-flex.justify-content-center.align-items-center", {
                                        style: {
                                            minHeight: "80vh"
                                        }
                                    }, [
                                        m(".spinner")
                                    ]),

                                ]),
                                m('.row.no-gutters', [
                                    m('.col-12.py-2', [
                                        m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                            m(Paging.Pagination, {
                                                pageNo: Card.paging.pageNo,
                                                pageCount: Card.paging.pageCount,
                                                pageUrl: Card.load.bind(this)
                                            }),
                                            m(Paging.PageItemCount, {
                                                pageNo: Card.paging.pageNo,
                                                pageSize: Card.paging.pageSize,
                                                total: Card.paging.totalRecordCount
                                            })
                                        ])
                                    ])
                                ])
                            ])

                        ])
                    ]),
                    m(".col-md-11.d-none.d-md-block.px-3", [
                        m(".row.no-gutters.w-100", [
                            m(".col-12", [
                                m(".row.no-gutters.w-100.py-2.px-4.font-weight-bold", {
                                    style: {
                                        background: "#A4D5D9",
                                        color: "white"
                                    }
                                }, [
                                    m(".col",
                                        "#"
                                    ),
                                    m(".col",
                                        "總類"
                                    ),
                                    m(".col-3",
                                        "公司"
                                    ),
                                    m(".col-1",
                                        "職稱"
                                    ),
                                    m(".col",
                                        "姓名"
                                    ),
                                    m(".col-1",
                                        "電話"
                                    ),
                                    m(".col",
                                        "分機"
                                    ),
                                    m(".col-1",
                                        "手機"
                                    ),
                                    // m(".col",
                                    //     "備註"
                                    // ),
                                    m(".col",
                                        "縣市"
                                    ),
                                    m(".col",
                                        "鄉鎮區"
                                    ),
                                    m(".col",
                                        "登入時間"
                                    ),
                                    m(".col",
                                        "操作"
                                    ),

                                ])
                            ]),
                            m(".col-12", [
                                m(".row.no-gutters.w-100", [
                                    (!Card.loading) ? [
                                    Card.data.map((_data, index) => {
                                        return m(".col-12.pt-1",
                                            m(".row.no-gutters.w-100.py-2.px-4.font-weight-bold.d-flex.align-items-center", {
                                                style: {
                                                    background: (index % 2 == 0) ? "#E8E8E8" : "#F4F4F4",
                                                    color: "#707070",
                                                    fontSize: "0.9rem"
                                                }
                                            }, [
                                                m(".col",
                                                    _data.ind
                                                ),
                                                m(".col",
                                                    { style: { fontSize: "0.8rem" } },
                                                    _data.cate
                                                ),
                                                m(".col-3",
                                                    _data.company
                                                ),
                                                m(".col-1",
                                                    _data.title
                                                ),
                                                m(".col",
                                                    _data.name
                                                ),
                                                m(".col-1",
                                                    _data.phone
                                                ),
                                                m(".col",
                                                    _data.phonecc
                                                ),
                                                m(".col-1",
                                                    _data.celephone
                                                ),
                                                // m(".col",
                                                //     _data.ps
                                                // ),
                                                m(".col",
                                                    _data.country
                                                ),
                                                m(".col",
                                                    _data.district
                                                ),
                                                m(".col",
                                                    (_data.updated_at != null) ? moment(_data.updated_at).format("YYYY-MM-DD") : ""
                                                ),
                                                m(".col",
                                                    [
                                                        m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0", {
                                                            style: {
                                                                background: "#C7D9A4",
                                                                color: "#FFF"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                Dialog.show(EditComponent, {
                                                                    attrs: {
                                                                        model: new Card(_data),
                                                                        country: this.countries
                                                                    },
                                                                    didHide: (returnValue) => {
                                                                        if (returnValue) {
                                                                            Card.load(Card.pageIndex, _item, Card.pageSize)
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-edit"),

                                                            ]
                                                        ),
                                                        m("button.btn.btn-danger.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button'].border-0", {
                                                            style: {
                                                                background: "#E6AAAA",
                                                                color: "#FFF"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            Card.delete(_data.ind).then(() => {
                                                                                swal("您的資料已成功刪除", {
                                                                                    icon: "success",
                                                                                    buttons: {
                                                                                        cancel: false,
                                                                                        confirm: "確認",
                                                                                    },
                                                                                }).then((response) => {
                                                                                    Card.load(Card.pageIndex, _item, Card.pageSize)
                                                                                })
                                                                            })
                                                                        }
                                                                    });
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-trash"),

                                                            ]
                                                        )
                                                    ]
                                                ),



                                            ])




                                        )
                                    })
                                        ,] : m(".col-12.text-center.d-flex.justify-content-center.align-items-center",{
                                            style:{
                                                minHeight:"80vh"
                                            }
                                        },[
                                            m(".spinner")
                                        ]) ,
                                ])
                            ])
                        ]),
                        m("table.table.table-hover.pt-3.border.mb-0",
                            [
                                m("thead",
                                    {
                                        style: {
                                            background: "#A4D5D9",
                                            color: "white"
                                        }
                                    },
                                    m("tr",
                                        [

                                        ]
                                    )
                                ),
                                m("tbody",
                                    [

                                    ]
                                )
                            ]
                        ),
                        m('.row.no-gutters.px-5', [
                            m('.col-12.py-3', [
                                m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                    m(Paging.Pagination, {
                                        pageNo: Card.paging.pageNo,
                                        pageCount: Card.paging.pageCount,
                                        pageUrl: Card.load.bind(this)
                                    }),
                                    m(Paging.PageItemCount, {
                                        pageNo: Card.paging.pageNo,
                                        pageSize: Card.paging.pageSize,
                                        total: Card.paging.totalRecordCount
                                    })
                                ])
                            ])
                        ])
                    ])

                ])
            ]),
        ])
    }
}