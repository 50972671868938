import m from 'mithril'
import * as Page from './pages'


const root = document.body
window.language = 'tw'

const routes = {
    '/': Page.DashboardPage,
    '/duplicate': Page.DuplicatePage,    
    "/:404...": Page.ErrorPage
}

export function render() {
    const loader = document.getElementById('loader');
    
    loader.classList.add('fadeOut');
    m.route(root, '/', routes)
}