import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    TextArea,
} from '@base/app/components'
import {
    Zipcode
} from '@base/app/models'



class EditComponent {
    constructor(vnode) {
        this.model = vnode.attrs.model      
        this.cates = ["從政人員", "公務人員", "公司", "個人", "廟宇", "記者"] 
        this.country = vnode.attrs.country
        
        this.select_county = []
        this.cities = []
    }
    save() {
        if (this.model.validate()) {
            return false
        }

        this.model.save().then((response) => {
            swal({
                title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                icon: "success",
                buttons: false,
                timer: 1800,
            })
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view() {
        return [m(".modal-dialog[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", ((this.model.id == 0) ? "新增" : "編輯" )),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m('.row.m-0', [                           
                            m('.col-lg-12', [
                                m(".form-group", [
                                    m("label.control-label.mb-1", "總類"),
                                    m("select.form-control", {
                                        onchange: (e) => {
                                            this.model.cate = e.target.value
                                        }
                                    },
                                        m("option"),
                                        [this.cates.map((item) => {
                                            return m("option", {
                                                value: item.id,
                                                selected: this.model.cate == item
                                            }, item)
                                        })]
                                    ),

                                ])
                            ]),
                            m('.col-lg-8', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.company = e.target.value
                                    },
                                    value: this.model.company,
                                    type: 'text',
                                    label: '公司',
                                })
                            ]),
                            
                            m('.col-lg-4', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.title = e.target.value
                                    },
                                    value: this.model.title,
                                    type: 'text',
                                    label: '職稱',
                                })
                            ]),
                            m('.col-lg-6', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.name = e.target.value
                                    },
                                    value: this.model.name,
                                    type: 'text',
                                    label: '姓名',
                                    validate: () => {
                                        this.model.valid('name')
                                    },
                                    error: this.model.error('name'),
                                })
                            ]),
                            m('.col-lg-6', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.celephone = e.target.value
                                    },
                                    value: this.model.celephone,
                                    type: 'text',
                                    label: '手機',
                                })
                            ]),
                            m('.col-lg-6', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.phone = e.target.value
                                    },
                                    value: this.model.phone,
                                    type: 'text',
                                    label: '電話',
                                })
                            ]),
                            m('.col-lg-4', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.phonecc = e.target.value
                                    },
                                    value: this.model.phonecc,
                                    type: 'text',
                                    label: '分機',
                                })
                            ]),
                            m('.col-lg-12', [
                                m(TextBox, {
                                    oninput: (e) => {
                                        this.model.email = e.target.value
                                    },
                                    value: this.model.email,
                                    type: 'text',
                                    label: '信箱',
                                })
                            ]),
                            m(".col-lg-12",[
                                m(".form-group", [
                                    m("label.control-label.mb-1", "縣市"),
                                    m("select.form-control", {
                                        onchange: (e) => {
                                            this.model.country = e.target.value
                                            this.cities = this.country()[this.country().findIndex(p => p.name == this.model.country)].datas
                                        }
                                    },
                                        m("option"),
                                        [this.country().map((item) => {
                                            return m("option", {
                                                value: item.name,
                                                selected: this.model.country == item.name
                                            }, item.name)
                                        })]
                                    ),

                                ])
                            ]),
                            m(".col-lg-12", [
                                m(".form-group", [
                                    m("label.control-label.mb-1", "縣市"),
                                    m("select.form-control", {
                                        onchange: (e) => {
                                            this.model.district = e.target.value
                                        }
                                    },
                                        m("option"),
                                        [this.cities.map((item) => {
                                            return m("option", {
                                                value: item,
                                                selected: this.model.district == item
                                            }, item)
                                        })]
                                    ),

                                ])
                            ]),
                            
                            m('.col-lg-12', [
                                m(TextArea, {
                                    oninput: (e) => {
                                        this.model.ps = e.target.value
                                    },
                                    value: this.model.ps,
                                    type: 'text',
                                    label: '備註',
                                })
                            ]),
                            
                        ]),
                    ),
                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [
                                    m("i.fa.fa-times-circle"),
                                    "取消"
                                ]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                    
                                }
                            },
                                [
                                    m("i.fa.fa-check-circle"),
                                    "儲存"
                                ]
                            )
                        ]
                    )
                ]
            )
        ])]
    }
}

export default EditComponent