import m from 'mithril'
import uuid from 'uuid'

import ConfirmDialog from './confirm'
import Snackbar from './snackbar'


let loop = [],
    backdrop = true,
    isLoading = false

const hasScroller = () => {
    return document.body.scrollHeight > document.body.clientHeight
}

const handleOption = (instance) => {

    let options = instance.options

    if (options === undefined) {
        return false
    }

    if (options.hasOwnProperty('backdrop')) {
        backdrop = options.backdrop
    } else {
        backdrop = true
    }

    if (options.hasOwnProperty('didHide')) {
        Dialog.events.push({
            id: instance.uuid,
            didHide: instance.options.didHide
        })
    }

}


const DialogPane = {
    oncreate: (vnode) => {
        setTimeout(() => {
            vnode.dom.classList.add("show")
            // if (!isMobile() && hasScroller()) {
            //     vnode.dom.style.paddingRight = '17px'
            // }

        }, 200)
    },
    onupdate: (vnode) => {
        if (!vnode.attrs.show) {
            vnode.dom.classList.remove("show")
            vnode.dom.style.paddingRight = 0
        }
    },
    view: (vnode) => {
        return m('.modal.fade.custom-modal', {
            style: {
                display: 'block'
            }
        }, [
            (vnode.attrs.fullscreen) ?
            m(DialogFullScreen, vnode.children) :
            vnode.children
        ])
    }
}

const Backdrop = {
    oncreate: (vnode) => {
        setTimeout(() => {
            vnode.dom.classList.add("show")
        }, 200)
    },
    onbeforeremove: (vnode) => {
        vnode.dom.classList.remove("show")
        return new Promise(function (resolve) {
            setTimeout(resolve, 200)
        })
    },
    view: (vnode) => {
        return m('.modal-backdrop.fade')
    }
}


const Dialog = {
    events: [],
    loading: (value) => {
        isLoading = value
    },
    show: (item, options, children, isToast) => {
        const instance = {
            component: item,
            uuid: uuid.v4(),
            options: Object.assign({
                modal: true
            }, options),
            attrs: (options === undefined || options === null) ? null : options.attrs,
            show: true,
            children: children,
            isToast: isToast
        }

        loop.push(instance)

        handleOption(instance)

        const body = document.body
        //Modal
        if (!instance.isToast) {
            if (!body.classList.contains('modal-open')) {
                body.classList.add('modal-open')
                // if (!isMobile() && hasScroller()) {
                //     body.style.paddingRight = '17px'
                // }
            }
        }

        m.redraw()
    },
    close: (returnValue, key) => {
        let closeItem, _index
        if (key) {
            _index = loop.findIndex((item) => {
                return item.uuid == key
            })
            closeItem = loop[_index]
        } else {
            _index = loop.length - 1
            closeItem = loop[_index]
        }
        const event = Dialog.events.find((item) => {
            return item.id === closeItem.uuid
        })
        let promise = new Promise((resolve, reject) => {
            if (event != undefined) {
                resolve(event.didHide(returnValue))
            }

        })
        closeItem.show = false
        setTimeout(() => {
            loop.splice(_index, 1)
            if (loop.length === 0) {
                const body = document.body
                body.classList.remove('modal-open')
                body.style.paddingRight = ""
            }
            m.redraw()
        }, 200);
        return promise
    },
    confirm: (content, confirmCallback, denyCallback) => {
        return new Promise((resolve, reject) => {
            Dialog.show(ConfirmDialog, {
                didHide: (returnValue) => {
                    resolve(returnValue);
                }
            }, content)
        }).then((response) => {
            if (response) {
                if (confirmCallback != undefined) {
                    confirmCallback()
                }
            } else if (denyCallback != undefined) {
                denyCallback()
            }
        })
    },
    snackbar: (content) => {
        Dialog.show(Snackbar, {
            backdrop: false
        }, content, true)
    },
    view: (vnode) => {
        return [
            (loop.length > 0) ?
            loop.map((item) => {
                if (item.isToast) {
                    return m(Snackbar, {
                        key: item.uuid,
                    }, item.children)
                }
                return m(DialogPane, {
                    key: item.uuid,
                    modal: (item.options.modal) ? true : false,
                    fullscreen: (item.options.fullscreen) ? true : false,
                    show: item.show
                }, m(item.component, item.attrs, item.children))
            }) : '',
            (loop.length > 0 && backdrop) ? m(Backdrop) : ''
        ]

    }
}


export default Dialog