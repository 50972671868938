import m from 'mithril'

class Zipcode {    
    constructor(args) {     

    }  

    static fetch() {
        Zipcode.loading = true
        return m.request({
            method: 'GET',
            url: `/api/zipcode`
        }).then((response) => {
            Zipcode.loading = false
            return response
        })
    }

    static load(id) {
        Zipcode.loading = true
        let url = `${window.BASE_URL}/api/zipcode/${id}`
        return m.request({
            method: 'GET',
            url: url
        }).then((response) => {
            Zipcode.loading = false
            return response
        })
    }
}

export default Zipcode