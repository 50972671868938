import m from 'mithril'
import classNames from 'classnames'
import anime from 'animejs/lib/anime.es.js';
import moment from 'moment'
import stream from 'mithril/stream'
import {
    Card,
    Zipcode
} from '@base/app/models'
import {
    Dialog,
    Paging,
    TextBox,
} from '@base/app/components'

import EditComponent from './edit'

export default class DuplicatePage {
    constructor() {
        this.datas = []
        Card.take_duplicate().then((_response) => {
            console.log(_response)
            this.datas = _response
        })
    }
    view() {
        return m('.container-fluid.text-card-play.h-100', {
            style: {
                padding: "1% 2%",
                backgroundImage: "linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%)"
            }
        }, [
            m(Dialog),
            (!Card.loading) ? "" :
                m(".loader-send", {
                    style: {
                        zIndex: "1024"
                    }
                }, [
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                    m(".loader-block"),
                ]),
            m(".w-100", [
                m(".row.no-gutters.w-100.py-3.text-card-play", [
                    m(".col-md-11.d-none.d-md-block", [
                        m("table.table.table-hover.pt-3.border",
                            [
                                m("thead.thead-dark",
                                    m("tr",
                                        [
                                            m("th[scope='col']",
                                                "#"
                                            ),
                                            m("th[scope='col']",
                                                "總類"
                                            ),
                                            m("th[scope='col']",
                                                "公司"
                                            ),
                                            m("th[scope='col']",
                                                "職稱"
                                            ),
                                            m("th[scope='col']",
                                                "姓名"
                                            ),
                                            m("th[scope='col']",
                                                "電話"
                                            ),
                                            m("th[scope='col']",
                                                "分機"
                                            ),
                                            m("th[scope='col']",
                                                "手機"
                                            ),
                                            m("th[scope='col']",
                                                "信箱"
                                            ),
                                            m("th[scope='col']",
                                                "備註"
                                            ),
                                            m("th[scope='col']",
                                                "縣市"
                                            ),
                                            m("th[scope='col']",
                                                "鄉鎮區"
                                            ),
                                            m("th[scope='col']",
                                                "操作"
                                            ),
                                        ]
                                    )
                                ),
                                m("tbody",
                                    [
                                        this.datas.map((_data) => {
                                            return m("tr",
                                                [
                                                    m("th[scope='row']",
                                                        _data.ind
                                                    ),
                                                    m("td",
                                                        _data.cate
                                                    ),
                                                    m("td",
                                                        _data.company
                                                    ),
                                                    m("td",
                                                        _data.title
                                                    ),
                                                    m("td",
                                                        _data.name
                                                    ),
                                                    m("td",
                                                        _data.phone
                                                    ),
                                                    m("td",
                                                        _data.phonecc
                                                    ),
                                                    m("td",
                                                        _data.celephone
                                                    ),
                                                    m("td",
                                                        _data.email
                                                    ),
                                                    m("td",
                                                        _data.ps
                                                    ),
                                                    m("td",
                                                        _data.country
                                                    ),
                                                    m("td",
                                                        _data.district
                                                    ),
                                                    m("td",
                                                        [
                                                            m("button.btn.btn-primary.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    Dialog.show(EditComponent, {
                                                                        attrs: {
                                                                            model: new Card(_data),
                                                                            country: this.countries
                                                                        },
                                                                        didHide: (returnValue) => {
                                                                            if (returnValue) {
                                                                                Card.load(Card.pageIndex, _item, Card.pageSize)
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            },
                                                                [
                                                                    m("i.fa.fa-edit"),

                                                                ]
                                                            ),
                                                            m("button.btn.btn-danger.btn-sm.mx-0.mx-md-1.my-1.my-md-0[type='button']", {
                                                                onclick: (e) => {
                                                                    e.preventDefault()
                                                                    swal({
                                                                        title: "是否確認刪除",
                                                                        text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                        icon: "warning",
                                                                        dangerMode: true,
                                                                        buttons: ["取消", "確認"]
                                                                    })
                                                                        .then((willDelete) => {
                                                                            if (willDelete) {
                                                                                Card.delete(_data.ind).then(() => {
                                                                                    swal("您的資料已成功刪除", {
                                                                                        icon: "success",
                                                                                        buttons: {
                                                                                            cancel: false,
                                                                                            confirm: "確認",
                                                                                        },
                                                                                    }).then((response) => {
                                                                                        Card.load(Card.pageIndex, _item, Card.pageSize)
                                                                                    })
                                                                                })
                                                                            }
                                                                        });
                                                                }
                                                            },
                                                                [
                                                                    m("i.fa.fa-trash"),

                                                                ]
                                                            )
                                                        ]
                                                    ),
                                                ]
                                            )
                                        })
                                    ]
                                )
                            ]
                        ),

                    ])

                ])
            ]),
        ])
    }
}