import m from 'mithril'
import uuid from 'uuid'
import stream from 'mithril/stream'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入姓名"
        }
    },
}

class Card extends BaseModel {
    
    constructor(args) {
        super(constraints)
        args = (args) ? args : {}
        this.ind = args.id || 0
        this.cate = args.cate || ""
        this.company = args.company || ""
        this.title = args.title || ""
        this.name = args.name || ""
        this.phone = args.phone || ""
        this.phonecc = args.phonecc || ""
        this.celephone = args.celephone || ""
        this.email = args.email || ""
        this.ps = args.ps || ""
        this.country = args.country || ""
        this.district = args.district || ""
    }


    static get data() {
        return _data;
    }

    static fetch() {
        Card.loading = true
        return m.request({
            method: 'GET',
            url: `/api/card`
        }).then((response) => {
            Card.loading = false
            return response
        })
    }

    static load(page, id, pageSize) {
        Card.id = (id === undefined) ? Card.id : id
        Card.loading = true
        page = (page === undefined) ? Card.pageIndex : page
        Card.pageIndex = page
        let url = `${window.BASE_URL}/api/card/${Card.id}?page=${page}&pageSize=${Card.pageSize}&search_word=${Card.search_word}`
        return m.request({
            method: 'GET',
            url: url
        }).then((response) => {
            Card.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data

            Card.loading = false

            return response.data
        })
    }

    save() {
        Card.loading = true
        return m.request({
            method: 'POST',
            url: `/api/card`,
            body: this
        }).then((response)=>{
            Card.loading = false
        })
    }

    static delete(ind) {
        Card.loading = true
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/card/${ind}`,
        }).then((response) => {
            Card.loading = false
        })
    }

    static take_duplicate() {
        Card.loading = true
        return m.request({
            method: 'get',
            url: `${window.BASE_URL}/api/card_duplicate`,
        }).then((response) => {
            
            Card.loading = false
            return response
        })
    }
}

export default Card